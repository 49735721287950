<template>
  <v-card flat max-width="550">
    <v-card-text>
      <v-row class="justify-center flex-wrap">
        <v-col
          v-for="(color, i) in colors"
          :key="i"
          style="min-width: 180px;"
          cols="12"
        >
          <m-color-picker
            v-model="theme[color]"
            :label="`${color.charAt(0).toUpperCase() + color.slice(1)} Color`"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="warning"
        outlined
        @click="resetDefaults"
      >
        Reset Defaults
      </v-btn>
      <v-btn
        color="primary"
        outlined
        :loading="saving"
        @click="updateTheme"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import MColorPicker from '@/components/core/MColorPicker'
export default {
  components: {
    MColorPicker
  },
  data: () => ({
    theme: {},
    saving: false,
    colors: [
      'primary',
      'secondary',
      'accent',
      'error',
      'info',
      'success',
      'warning'
    ]
  }),
  computed: {
    layout() {
      return this.$store.getters.layoutById(this.$route.params.id)
    }
  },
  watch: {
    layoutTheme: {
      handler(layoutTheme) {
        this.theme = this.layout.theme || this.$vuetify.theme.defaults.light
      },
      immediate: true
    }
  },
  methods: {
    async updateTheme() {
      this.saving = true
      await this.$db.collection('layouts').doc(this.layout.id).update({ theme: this.theme })
      this.saving = false
    },
    resetDefaults() {
      this.theme = JSON.parse(JSON.stringify(this.$vuetify.theme.defaults.light))
    }
  }
}
</script>
